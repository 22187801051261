<template>
  <v-container>
    <v-row class="fill-height">
      <v-col md="12" class="pt-5 px-6">
        <h3>Beauty Maker</h3>
        <v-divider></v-divider>

        <v-form
          ref="formBM"
          v-model="formIsValid"
          v-on:submit.prevent="bmSubmit(bm.id)"
        >
          <v-col md="12">
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="bm.name"
                  :rules="textRules"
                  label="Nome"
                  placeholder="Nome"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col xl="4" lg="4" md="4" sm="6">
                <v-text-field
                  v-model="bm.document"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  label="Documento"
                  placeholder=""
                  :rules="textRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="6">
                <v-select
                  v-model="bm.serviceStepTime"
                  :items="items"
                  item-text="desc"
                  item-value="value"
                  label="Fator tempo de serviço"
                  :rules="textRules"
                  outlined
                ></v-select>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="6">
                <v-text-field
                  label="Comissão sobre serviço"
                  append-icon="mdi-percent"
                  v-model="bm.commissionService"
                  placeholder="Comissão sobre serviço"
                  v-mask="['##,##', '#,##']"
                  :rules="numberRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="6">
                <v-text-field
                  label="Comissão sobre produto"
                  append-icon="mdi-percent"
                  v-model="bm.commissionProduct"
                  placeholder="Comissão sobre serviço"
                  v-mask="['##,##', '#,##']"
                  :rules="numberRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="6">
                <v-text-field
                  label="Comissão sobre insumo"
                  append-icon="mdi-percent"
                  v-model="bm.commissionSupply"
                  placeholder="Comissão sobre serviço"
                  v-mask="['##,##', '#,##']"
                  :rules="numberRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="12">
                <v-text-field
                  v-model="bm.obs"
                  :rules="textRules"
                  label="Observação"
                  placeholder="Digite alguma observação"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="6">
                <v-file-input
                  v-model="img"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Escolha uma imagem."
                  prepend-inner-icon="mdi-camera"
                  prepend-icon=""
                  label="Imagem"
                  outlined
                  @change="previewImage"
                ></v-file-input>
              </v-col>
              <v-col xl="2" lg="2" md="2" sm="12">
                <v-card elevation="5">
                  <v-img :src="bm.image" max-height="100px" contain></v-img>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="12">
                <h3>Serviços.</h3>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row
              v-for="(bmService, index) in bm.services"
              :key="index + 'serv'"
            >
              <v-col xl="6" lg="6" md="6" sm="6">
                <v-select
                  v-model="bmService.id"
                  :items="services"
                  item-value="id"
                  label="Serviço"
                  placeholder="Escolha um serviço"
                  :rules="textRules"
                  outlined
                >
                  <template v-slot:selection="{ item }">
                    {{ item.categoryName }} - {{ item.name }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.categoryName }} - {{ item.name }}
                  </template>
                </v-select>
              </v-col>

              <v-col xl="4" lg="4" md="4" sm="4">
                <v-text-field
                  label="Duração"
                  append-icon="mdi-clock-fast"
                  v-model.number="bmService.duration"
                  placeholder="Tempo para executar o serviço"
                  suffix="minutos"
                  :rules="[durationRule]"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col xl="2" lg="2" md="2" sm="2" class="justify-center">
                <v-tooltip bottom v-if="1 != bm.services.length">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="orange"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-3"
                      v-on:click="removeService(index)"
                    >
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Remover Serviço</span>
                </v-tooltip>
                <v-tooltip bottom v-if="index == bm.services.length - 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-3"
                      v-on:click="addService()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar Serviço</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <h3>Dias de trabalho.</h3>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row
              v-for="(bmWeekDay, index) in bm.weekDays"
              :key="index + 'wk'"
            >
              <v-col xl="3" lg="3" md="3" sm="4">
                <v-select
                  v-model="bmWeekDay.id"
                  :items="weekDays"
                  item-value="id"
                  item-text="name"
                  label="Dia da semana"
                  placeholder="Escolha um dia da semana"
                  :rules="textRules"
                  outlined
                ></v-select>
              </v-col>

              <v-col xl="3" lg="3" md="3" sm="4">
                <v-text-field
                  label="Início"
                  append-icon="mdi-clock-time-four-outline"
                  v-model="bmWeekDay.startTime"
                  placeholder="Horário de abertura"
                  v-mask="['##:##']"
                  :rules="hourRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="4">
                <v-text-field
                  label="Fim"
                  append-icon="mdi-clock-time-seven-outline"
                  v-model="bmWeekDay.endTime"
                  placeholder="Horário de fechamento"
                  v-mask="['##:##']"
                  :rules="hourRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col xl="2" lg="2" md="2" sm="2" class="justify-center">
                <v-tooltip bottom v-if="1 != bm.weekDays.length">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="orange"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-3"
                      v-on:click="removeWeekDay(index)"
                    >
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Remover Dia de trabalho</span>
                </v-tooltip>
                <v-tooltip bottom v-if="index == bm.weekDays.length - 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-3"
                      v-on:click="addWeekDay()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar Dia de trabalho</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" class="text-right">
                <v-btn color="error" v-if="bm.id" class="mr-4" @click="reset">
                  <v-icon>mdi-close</v-icon>
                  Cancelar
                </v-btn>
                <v-btn color="error" v-else class="mr-4" @click="reset">
                  <v-icon>mdi-close</v-icon>
                  Limpar
                </v-btn>
                <v-btn type="submit" color="success">
                  <v-icon left v-if="bm.id">mdi-account-edit </v-icon>
                  <v-icon left v-else>mdi-plus</v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { mask } from "vue-the-mask";

import { BM_GET, BM_EDIT, BM_CREATE } from "@/store/actions/bm.type";
import { WEEKDAYS_GET } from "@/store/actions/weekday.type";
import { SERVICES_GET } from "@/store/actions/service.type";
import {
  BM_ADD_SERVICE,
  BM_ADD_WEEKDAY,
  BM_REMOVE_SERVICE,
  BM_REMOVE_WEEKDAY,
  BM_RESET,
  BM_RESET_STATE,
  SET_BM_IMAGE
} from "@/store/mutations/bm.type";
import { UPDATE_PERMISSION } from "@/store/mutations/user.type";
// import router from "@/router";

export default {
  name: "BMEdit",
  directives: { mask },
  props: {
    id: {
      default: 0,
      type: Number,
      required: false
    }
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(BM_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(BM_RESET_STATE);
    if (to.params.id !== undefined) await store.dispatch(BM_GET, to.params.id);
    await store.dispatch(SERVICES_GET);
    await store.dispatch(WEEKDAYS_GET);

    return next();
  },
  data: () => ({
    items: [
      { value: 5, desc: "5 minutos" },
      { value: 10, desc: "10 minutos" },
      { value: 15, desc: "15 minutos" },
      { value: 30, desc: "30 minutos" },
      { value: 60, desc: "60 minutos" }
    ],
    img: null,
    selectMaterialOK: "",
    formIsValid: false,
    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    numberRules: [
      v => {
        // if (!v.trim()) return true;
        if (typeof v !== "undefined" && v !== null) {
          if (!isNaN(v) && v >= 0.0 && v <= 100) return true;
        }
        return "Informe um valor de 0 a 100";
      }
    ],
    cpfRule: [
      v => !!v || "CPF é obrigatório",
      strCPF => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      }
    ],
    fileRules: [
      value => !!value || "Campo obrigatório."
      // value => !value || value.size < 2000000 || "A imagem tem que ter menos de  2 MB!",
    ],
    emailRules: [
      v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Informe um email válido";
          }
        }
        return false;
      }
    ],
    hourRules: [
      v => !!v || "Campo obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
            return pattern.test(value) || "Informe um horário válido";
          }
        }
        return false;
      }
    ],
    telRules: [
      v => !!v || "Telefone é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})$/;
            return pattern.test(value) || "Informe um telefone válido";
          }
        }
        return false;
      }
    ]
  }),
  methods: {
    async previewImage() {
      if (this.img) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });

        store.commit(SET_BM_IMAGE, await toBase64(this.img));
      }
    },
    durationRule(v) {
      if (!v) {
        return "Este campo é obrigatório";
      }
      if (typeof v !== "undefined" && v !== null) {
        if (v % this.bm.serviceStepTime !== 0) {
          return "Este campo precisa ser múltiplo do fator de serviço";
        }
      }
      return true;
    },
    addService() {
      if (this.bm.services.length < this.services.length)
        this.$store.commit(BM_ADD_SERVICE);
    },
    removeService(index) {
      if (this.bm.services.length > 1)
        this.$store.commit(BM_REMOVE_SERVICE, index);
    },
    addWeekDay() {
      this.$store.commit(BM_ADD_WEEKDAY);
    },
    removeWeekDay(index) {
      if (this.bm.weekDays.length > 1)
        this.$store.commit(BM_REMOVE_WEEKDAY, index);
    },

    permissionValue(permission) {
      let permLevel = this.bm.permissions.find(p => p.id === permission);
      if (typeof permLevel !== "undefined") {
        return { id: permLevel.levelID };
      }
      return null;
    },
    updatePermissions(permission, level) {
      this.permissions.find((p, i) => {
        if (p.id === permission.id) {
          let permission = { id: p.id, levelID: level.id };
          this.$store.commit(UPDATE_PERMISSION, {
            i: i,
            permission: permission
          });
          // this.bm.permissions[i] = {id: p.id, levelID: level.id};
        }
      });
    },
    passwordRule(pass) {
      if (!pass && this.id != 0) return true;
      return !!pass || "Este campo é obrigatório";
    },
    reset() {
      if (this.$route.params.id > 0) {
        this.$router.go(-1);
      } else {
        this.$refs.formBM.resetValidation();

        store.commit(BM_RESET);
      }
    },
    bmSubmit(id) {
      if (this.$refs.formBM.validate()) {
        let action = id ? BM_EDIT : BM_CREATE;
        this.inProgress = true;
        // this.bloqueiaEnvio = true;
        this.$store
          .dispatch(action)
          .then(response => {
            this.inProgress = false;
            this.bloqueiaEnvio = false;

            // this.$refs.formBM.resetValidation();
            // store.commit(BM_RESET);
            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";
            if (response.data.id) {
              this.$router.push({
                name: "bm",
                params: { id: response.data.id }
              });
            } else {
              this.$router.push({
                name: "bm",
                params: { id: id }
              });
            }
          })
          .catch(({ response }) => {
            this.inProgress = false;
            this.snackbar.bloqueiaEnvio = false;
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar Beauty Maker.";
            // console.log(response.data);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar Beauty Maker: " + response.data.message;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    }
  },
  mounted() {},
  computed: {
    // ...mapState({
    //   courses: state => state.
    // })

    ...mapGetters(["bm", "permissions", "services", "snackbar", "weekDays"])
  },
  watch: {}
};
</script>
